import React, { useEffect } from "react";
import scrollTo from "../../../utils/scrollTo";
import { windowHeight } from "../../../utils/windowDimensions";
import Image from "gatsby-image";

const HeroImage = ({ heroImage, heroImageMobile, auto_scroll }) => {
  useEffect(() => {
    if (auto_scroll) {
      let timer;
      const startScroll = () => {
        timer = setTimeout(() => {
          if (window.scrollY === 0) {
            scrollTo(windowHeight);
          }
        }, 2500);
      };
      const stopScroll = () => {
        clearTimeout(timer);
      };
      startScroll();
      return () => {
        stopScroll();
      };
    }
  }, [auto_scroll]);
  return (
    <>
      {heroImage && heroImage.fluid ? (
        <div className="relative h-screen mb-ogs hidden lg:block">
          <Image
            className="w-full h-full relative bg-bleached-silk z-10"
            placeholderStyle={{ display: "none" }}
            fluid={{
              ...heroImage.fluid,
              aspectRatio: 1.8
            }}
            loading="eager"
          />
        </div>
      ) : (
        <div className="w-full h-full bg-bleached-silk relative" />
      )}
      {heroImageMobile && heroImageMobile.fluid ? (
        <div className="relative h-screen mb-ogs lg:hidden">
          <Image
            className="w-full h-full relative bg-bleached-silk z-10"
            placeholderStyle={{ display: "none" }}
            fluid={{
              ...heroImageMobile.fluid,
              aspectRatio: 0
            }}
            loading="eager"
          />
        </div>
      ) : (
        <div className="w-full h-full bg-bleached-silk relative" />
      )}
    </>
  );
};

export default HeroImage;
