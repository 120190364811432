import React, { useEffect } from "react";
import Layout from "../components/Layout";
import PropTypes from "prop-types";
import classNames from "classnames";
import scrollTo from "../utils/scrollTo.js";

import TestimonialBlock from "../components/FlexibleContentSlices/Testimonials/testimonials.js";
import GalleryBlock from "../components/FlexibleContentSlices/GallerySwiper/gallerySwiper";
import TextBlock from "../components/FlexibleContentSlices/TextBlock/textBlock";
import { Accordion } from "../components/FlexibleContentSlices/Accordion/accordion";
import VideoBlock from "../components/FlexibleContentSlices/VideoPlayer/videoPlayer";
import YoutubeEmbed from "../components/FlexibleContentSlices/YoutubeEmbed/youtubeEmbed";
import ImageBlock from "../components/FlexibleContentSlices/ImageBlock/imageBlock";
import HomepageHero from "../components/HomepageHero/homepageHero";
import TextAndImageBlock from "../components/FlexibleContentSlices/TextAndImageBlock/textAndImageBlock";
import PanelsBlock from "../components/FlexibleContentSlices/PanelsBlock/panelsBlock";
import FeaturedArticleBlock from "../components/FlexibleContentSlices/FeaturedArticleBlock/featuredArticleBlock";
import ImageAndImageBlock from "../components/FlexibleContentSlices/ImageAndImageBlock/imageAndImageBlock";
import HeroImage from "../components/FlexibleContentSlices/HeroImage/heroImage";
import FormBlock from "../components/FlexibleContentSlices/Form/formBlock.js";

const FlexibleContentTemplate = ({ pageContext, location }) => {
  const { data: templateData } = pageContext;

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        scrollTo(elementPosition);
      }
    }
  }, [location.hash]);

  const hasHeroImage = templateData.hero_image && templateData.hero_image.fluid;

  return (
    <Layout
      seoTitle={templateData.meta_title || templateData.title || "Page"}
      seoDescription={templateData.meta_description}
      location={location}
      hasMenuIcon
    >
      <div
        className={classNames("leading-relaxed text-eerie-black font-niveau", {
          "mt-hh": !hasHeroImage
        })}
      >
        {templateData.hero_image.fluid && (
          <HeroImage
            heroImage={templateData.hero_image}
            heroImageMobile={templateData.hero_image_mobile}
            auto_scroll={templateData.enable_auto_scroll}
          />
        )}
        {templateData.slices &&
          templateData.slices.map((slice, index) => {
            let aspectRatio = 0;
            let imageDescriptionPaddingClassList = [
              "lg:flex lg:justify-between mt-8"
            ];
            let spacingClassList = [""];
            let isSecondLastSlice = index === templateData.slices.length - 2;
            let isLastSlice = index === templateData.slices.length - 1;
            let textPaddingClassList = ["lg:w-1/2 self-center"];
            let parentContainerClassList = [
              "flex flex-col-reverse md:items-center lg:flex-row"
            ];
            if (isLastSlice && slice.primary?.space_below === "None") {
              parentContainerClassList.push("pb-0");
              spacingClassList.push("pb-0");
            }
            let imagesContainerClassList = ["my-6 md:my-0"];

            if (slice.primary?.space_below === "Double") {
              parentContainerClassList.push("pb-16 md:pb-32");
              spacingClassList.push("pb-16 md:pb-32");
            } else if (slice.primary?.space_below === "Single") {
              parentContainerClassList.push("pb-6 md:pb-10");
              spacingClassList.push("pb-6 md:pb-10");
            } else if (isSecondLastSlice || isLastSlice) {
              parentContainerClassList.push("mb-0");
              spacingClassList.push("pb-0");
            } else {
              parentContainerClassList.push("pb-8 md:pb-0");
              spacingClassList.push("pb-8 md:pb-0");
            }

            if (slice.primary?.space_above === "Double") {
              parentContainerClassList.push("mt-16 md:mt-32");
              spacingClassList.push("mt-16 md:mt-32");
            }
            if (slice.primary?.space_above === "Single") {
              parentContainerClassList.push("pt-6 md:pt-10");
              spacingClassList.push("pt-6 md:pt-10");
            } else {
              if (isLastSlice) {
                parentContainerClassList.push("pt-0");
                spacingClassList.push("pt-0");
              } else {
                parentContainerClassList.push("mt-8 md:mt-0");
                spacingClassList.push("mt-8 md:mt-0");
              }
            }

            if (slice.primary?.image_alignment === "Left") {
              parentContainerClassList.push("lg:flex-row-reverse");
              if (slice.primary?.image_width === "Reduced") {
                parentContainerClassList.push("px-ogs lg:px-ogs");
                imagesContainerClassList.push("w-full lg:w-1/2");
                textPaddingClassList.push("lg:pl-ogs");

                aspectRatio = 1.4;
              } else if (slice.primary?.image_width === "Full") {
                parentContainerClassList.push("lg:pr-ogs");
                textPaddingClassList.push("px-ogs lg:px-0");
                imagesContainerClassList.push("px-ogs lg:pl-0 w-full lg:w-2/3");
                imageDescriptionPaddingClassList.push("lg:pl-ogs");
                aspectRatio = 1;
              } else if (slice.primary?.image_width === "Wide") {
                parentContainerClassList.push("lg:pr-ogs");
                textPaddingClassList.push("px-ogs");
                imagesContainerClassList.push("lg:w-4/5");
                imageDescriptionPaddingClassList.push("lg:pl-ogs");
                aspectRatio = 2;
              }
            } else {
              if (slice.primary?.image_width === "Reduced") {
                parentContainerClassList.push("px-ogs lg:px-ogs");
                imagesContainerClassList.push("w-full lg:w-1/2");
                textPaddingClassList.push("lg:pr-ogs");

                aspectRatio = 1.4;
              } else if (slice.primary?.image_width === "Full") {
                parentContainerClassList.push("lg:pl-0 lg:pl-ogs");
                textPaddingClassList.push("px-ogs lg:px-0");
                imagesContainerClassList.push("px-ogs lg:pr-0 w-full lg:w-2/3");
                imageDescriptionPaddingClassList.push("lg:pr-ogs");
                aspectRatio = 1;
              } else if (slice.primary?.image_width === "Wide") {
                parentContainerClassList.push(
                  "px-ogs lg:px-ogs lg:pr-0 lg:pl-ogs"
                );
                imagesContainerClassList.push("w-full lg:w-4/5");
                imageDescriptionPaddingClassList.push("lg:pr-ogs");
                textPaddingClassList.push("lg:pr-ogs");

                aspectRatio = 2;
              }
            }
            if (slice.slice_type === "homepage_hero") {
              return <HomepageHero slice={slice} key={index} />;
            }

            if (slice.slice_type === "video_block") {
              return (
                <div
                  id={slice.primary.item_id}
                  className={classNames(
                    parentContainerClassList,
                    "relative w-full"
                  )}
                  key={index}
                >
                  <VideoBlock slice={slice} index={index} />
                </div>
              );
            }
            if (slice.slice_type === "youtube_embed_block") {
              return (
                <div
                  id={slice.primary.item_id}
                  className={classNames(
                    parentContainerClassList,
                    "relative w-full"
                  )}
                  key={index}
                >
                  <YoutubeEmbed slice={slice} index={index} />
                </div>
              );
            }

            if (slice.slice_type === "text_block") {
              return (
                <div
                  id={slice.primary.item_id}
                  key={index}
                  className={classNames(spacingClassList, "px-ogs")}
                >
                  <TextBlock slice={slice} index={index} />
                </div>
              );
            }

            if (slice.slice_type === "gallery_block") {
              return (
                <div
                  id={slice.primary.item_id}
                  className={classNames(spacingClassList, "md:h-screen")}
                  key={index}
                >
                  <GalleryBlock items={slice.items} />
                </div>
              );
            }

            if (slice.slice_type === "featured_article_block") {
              return (
                <div
                  key={index}
                  id={slice.primary.item_id}
                  className={classNames(spacingClassList)}
                >
                  <FeaturedArticleBlock slice={slice} index={index} />
                </div>
              );
            }

            if (slice.slice_type === "testimonial_block") {
              return (
                <div
                  id={slice.primary.item_id}
                  key={index}
                  className={classNames(spacingClassList, "px-ogs")}
                >
                  <TestimonialBlock slice={slice} index={index} />
                </div>
              );
            }

            if (slice.slice_type === "accordion") {
              return (
                <div
                  id={slice.primary.item_id}
                  key={index}
                  className={classNames(
                    spacingClassList,
                    "bg-white text-eerie-black"
                  )}
                >
                  <Accordion slice={slice} />
                </div>
              );
            }
            if (slice.slice_type === "image_block") {
              return (
                <div id={slice.primary.item_id}>
                  <ImageBlock
                    id={slice.primary.item_id}
                    slice={slice}
                    index={index}
                    spacingClassList={spacingClassList}
                    key={index}
                  />
                </div>
              );
            }
            if (
              slice.slice_type === "text_and_image_block" ||
              slice.slice_type === "cta_block"
            ) {
              return (
                <div id={slice.primary.item_id}>
                  <TextAndImageBlock
                    id={slice.primary.item_id}
                    slice={slice}
                    index={index}
                    parentContainerClassList={parentContainerClassList}
                    textPaddingClassList={textPaddingClassList}
                    imagesContainerClassList={imagesContainerClassList}
                    aspectRatio={aspectRatio}
                    imageDescriptionPaddingClassList={
                      imageDescriptionPaddingClassList
                    }
                    key={index}
                  />
                </div>
              );
            }
            if (slice.slice_type === "lead_form_cta") {
              return (
                <div id={slice.primary.item_id}>
                  <FormBlock
                    id={slice.primary.item_id}
                    slice={slice}
                    index={index}
                    parentContainerClassList={parentContainerClassList}
                    textPaddingClassList={textPaddingClassList}
                    imagesContainerClassList={imagesContainerClassList}
                    aspectRatio={aspectRatio}
                    imageDescriptionPaddingClassList={
                      imageDescriptionPaddingClassList
                    }
                    key={index}
                  />
                </div>
              );
            }

            if (slice.slice_type === "panels_block") {
              return (
                <div id={slice.primary.item_id}>
                  <PanelsBlock
                    id={slice.primary.item_id}
                    slice={slice}
                    index={index}
                    spacingClassList={spacingClassList}
                    key={index}
                  />
                </div>
              );
            }
            if (slice.slice_type === "image___image_block") {
              return (
                <div id={slice.primary.item_id}>
                  <ImageAndImageBlock
                    id={slice.primary.item_id}
                    slice={slice}
                    spacingClassList={spacingClassList}
                    key={index}
                  />
                </div>
              );
            }

            return null;
          })}
      </div>
    </Layout>
  );
};

FlexibleContentTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default FlexibleContentTemplate;
