/* global gtag */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Image from "gatsby-image";
import LinkIcon from "../../Link/LinkIcon";

const FormBlock = ({
  slice,
  index,
  parentContainerClassList,
  textPaddingClassList,
  imagesContainerClassList,
  aspectRatio,
  imageDescriptionPaddingClassList
}) => {
  const [email, setEmail] = useState("");

  const heading = slice.primary?.heading?.text;
  const text = slice.primary?.text?.html;
  const imageTitle = slice.primary?.image_title?.text;
  const image = slice.primary?.image?.fluid;
  const bgColour =
    slice.primary?.background_colour === "Grey" ? "md:bg-bleached-silk" : "";

  useEffect(() => {
    const form = document.getElementById("my-form");

    async function handleSubmit(event) {
      event.preventDefault();
      const status = document.getElementById("my-form-status");
      const data = new FormData(event.target);
      try {
        const response = await fetch(event.target.action, {
          method: form.method,
          body: data,
          headers: {
            Accept: "application/json"
          }
        });

        if (response.ok) {
          status.innerHTML = "Thanks for your submission!";
          form.reset();
          if (typeof gtag === "function") {
            gtag("set", "user_data", {
              email: email
            });
            console.log("Sending conversion event");
            gtag("event", "conversion", {
              send_to: process.env.GATSBY_GTAG_CONVERSION_ID
            });
          }
          setEmail("");
        } else {
          let errorMessage = "Oops! There was a problem submitting your form";
          try {
            const errorData = await response.json();
            if (errorData && errorData.errors) {
              errorMessage = errorData.errors
                .map(error => error.message)
                .join(", ");
            }
          } catch (jsonError) {
            console.error("Error parsing JSON:", jsonError);
          }
          status.innerHTML = errorMessage;
        }
      } catch (error) {
        console.error("Fetch error:", error);
        status.innerHTML = "Oops! There was a problem submitting your form";
      }
    }

    form.addEventListener("submit", handleSubmit);

    return () => {
      form.removeEventListener("submit", handleSubmit);
    };
  }, [email]);

  return (
    <div key={index} className={classNames(parentContainerClassList, bgColour)}>
      <div
        className={classNames(
          textPaddingClassList,
          "my-6 md:my-12 lg:my-0 lg:py-12"
        )}
      >
        {heading && (
          <h2 className="font-normal text-2xl lg:text-3xl xl:text-4xl leading-tight lg:leading-snug">
            {heading}
          </h2>
        )}
        {text && (
          <div
            className="mt-4 md:mt-6 text-base md:text-lg lg:text-xl leading-relaxed rte font-light"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        <form
          id="my-form"
          action={process.env.GATSBY_FORMSPREE_ENDPOINT}
          method="POST"
          className="space-y-4"
        >
          <div>
            <label className="block mb-2">Name:</label>
            <input
              className={classNames(
                bgColour,
                "w-full p-2 border-b focus:outline-none"
              )}
              type="text"
              name="name"
            />
          </div>
          <div>
            <label className="block mb-2">Email:</label>
            <input
              className={classNames(
                bgColour,
                "w-full p-2 border-b focus:outline-none"
              )}
              type="email"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="w-full">
            <label className="block mb-2">Tell us about your project:</label>
            <textarea
              className={classNames(
                bgColour,
                "w-full p-2 border-b focus:outline-none"
              )}
              name="message"
            />
          </div>
          <button
            id="my-form-button"
            className="inline-flex gap-2 mt-10 items-center pt-2 link focus:outline-none"
          >
            <div className="h-5 w-5">
              <LinkIcon />
            </div>
            <p className="tracking-wider pb-1 uppercase font-medium">Submit</p>
          </button>
          <p id="my-form-status" className="mt-2 text-red-500"></p>
        </form>
      </div>
      <div className={classNames(imagesContainerClassList)}>
        {image && (
          <Image
            placeholderStyle={{ display: "none" }}
            fluid={{ ...image, aspectRatio: aspectRatio }}
            objectCover
            loading="lazy"
          />
        )}
        {imageTitle && (
          <div className={classNames(imageDescriptionPaddingClassList)}>
            <h2 className="uppercase text-xs md:text-sm whitespace-no-wrap tracking-wider">
              {imageTitle}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormBlock;
